<template>
	<div class="section_right">
		<!--section_right-->
		<div class="sub_top">
			<!--sub_top-->
			<div class="sub_title"><i class="far fa-piggy-bank text-primary"></i>{{ $t('membership.6') }}</div>
		</div>
		<div class="section_right_inner">
			


			<div class="s_left">
				<!--s_left-->
				<div class="withdrawal_section01">
					<!--withdrawal_section01-->
					<p class="withdrawal_section01_t"><span>{{ $t('membership.7') }}</span></p>
					<!--total_box end-->
					<div name="packagefrm">
						<div class="withdrawal_input_box">
							<!--withdrawal_input_box-->
							<table style="width:100%;">
								<tbody>
									
									<tr>
										<td>{{ $t('history.7') }}</td>
										<td>
											<select class="withdrawal_input01" v-model='sales' @change="ChangeSales()">
												<option value='' style='display: none'>{{ $t('deposit.4') }}</option>
												<option :value='item.code' style='' v-for="(item,index) in sales_list" :key="index">{{numberWithCommas(item.point)}}</option>
											</select>
										</td>
									</tr>
									<tr v-if="t_balance !=''">
										<td>{{ $t('withdraw_point.36') }}</td>
										<td>
											<div>
												<input type="text" name="addres" id="balance" class="withdrawal_input01" :value="t_balance" readonly="">
											</div>
											
										</td>
									</tr>
								</tbody>
							</table>
							<table style="width:100%;">
								<tbody>
									<tr>
										<td>{{ $t('withdraw_point.8') }}</td>
										<td>
											<select class="withdrawal_input01" v-model='coin' @change="ChangeCoin()">
												<option value='' style='display: none'>{{ $t('deposit.4') }}</option>
												<option :value='item.coin' style='' v-for="(item,index) in coin_list" :key="index">{{item.coin}}</option>
											</select>
										</td>
									</tr>
									<tr v-if="contract !=''">
										<td>{{net}} {{$t('deposit.5')}}</td>
										<td>
											<div>
												<input type="text" name="addres" id="balance" class="withdrawal_input01" :value="base_balance" readonly="">
											</div>
											
										</td>
									</tr>
									<tr v-if="coin !=''">
										<td>{{coin}} {{$t('assets.13')}}</td>
										<td>
											<div>
												<input type="text" name="addres" id="balance" class="withdrawal_input01" :value="balance" readonly="">
											</div>
											
										</td>
									</tr>
									
									<tr v-if="address !=''">
										<td>{{$t('deposit.6')}}</td>
										<td>
											<p class="qrcode text-center">
												<vue-qrcode :value="address" style="max-width:150px;" />
											</p>
											<div class="flex-between-center mb-4">
												<input type="text" name="addres" id="recaddr" class="index_input01" :value="address" readonly="">
												<div><i class="far fa-copy text-primary flex-shrink-0 chk_btn text-center"  onclick="copy_to_clipboard()"> {{$t('landing.51')}}</i></div>
											</div>
											
										</td>
									</tr>
								</tbody>
							</table>
							<div><button type="button" id="next" class="btn-qrcode" @click="SalesConfirm()">{{ $t('membership.6') }}</button></div>
						</div>
					</div>
				</div>
				<!--withdrawal_section01-->

				
			</div>
			
		</div>
		

		
	</div>
	<!--section_right end-->
</template>
<script>
const exactMath = require('exact-math');
const CryptoJS = require("crypto-js");
import VueQrcode from 'vue-qrcode'
	export default {
		components: {
			VueQrcode,
		},
		data(){
			return{
				address: '',
				balance : '',
				contract : '',
				base_balance : '',
				coin_list : [],
				sales_list : [],
				sales:'',
				coin : '',
				jejus_rate : '',
				rate : '',
				t_balance : '',
				net : '',
				s_able : 'N',
			}
		},
	    mounted(){
			this.GetCoinInfo();
			this.GetSaleInfo();
			this.GetAble();
		},
		methods: {
			GetAble(){
				
				const body = {};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/point/GetAble`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)
								this.s_able = body.info.sales;

							}else if(res.data.code=='9999'){
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
			},
			SalesConfirm(){
				const coin = this.coin;
				const sales = this.sales;
				const rate = this.rate;
				const t_balance = this.t_balance;
				const balance = this.balance;

				const s_able = this.s_able;
				
				if(s_able =='N'){
					this.$alert(this.$t('deposit.10'))
					return false;
				}
				if(coin ==''){
					this.$alert(this.$t('deposit.4'))
					return false;
				}

				if(sales ==''){
					this.$alert(this.$t('deposit.9'))
					return false;
				}

				if(t_balance > balance){
					this.$alert(this.$t('transfer.15'))
					return false;
				}

				const body = {coin,sales,rate,t_balance};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/sales/SalesConfirm`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								// const e_body = res.data.body;
								// const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								// const d_res = bytes.toString(CryptoJS.enc.Utf8);
								// const body = JSON.parse(d_res)
								// this.sales_list = body.list;
								// this.rate = body.info.balance;
							}else if(res.data.code=='9999'){
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
			},
			ChangeCoin(){
				this.GetWalletBalance()

			},
			GetSaleInfo(){
				
				const body = {};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/sales/GetSaleList`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)
								this.sales_list = body.list;
								this.rate = body.info.rate;
							}else if(res.data.code=='9999'){
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
			},
			GetWalletBalance(){
				const coin = this.coin;
				const body = {coin};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.balance = '';
				this.address = '';
				this.contract = '';
				this.base_balance = '';
				this.net = '';
				this.$http.post(`/member/sales/GetWalletBalance`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)
								this.balance = body.balance;
								this.address = body.address;
								this.contract = body.contract;
								this.base_balance = body.base_balance
								this.net = body.net
							}else if(res.data.code=='9999'){
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
				
			},
			ChangeSales(){
				const sales = this.sales;
				const rate = this.rate;
				let t_amount = '';
				
				for (const el of this.sales_list) {

					if(sales ==el.code){
						t_amount = el.point;
					}
				}

				const t_balance = exactMath.mul(t_amount,rate);
				this.t_balance = t_balance
			},
			GetCoinInfo(){
				
				const body = {};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/sales/GetCoinList`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)

								this.coin_list = body.list;
								

								
							}else if(res.data.code=='9999'){
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
			},
			
			numberWithCommas(x) {
				var parts=x.toString().split(".");
				return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
			},
		}
	}
</script>
<style>
</style>